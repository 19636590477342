import { Routes, Route } from 'react-router-dom';
import Landing from './pages/LandingPage';
import React from "react";
import {PrivateRoute} from "src/common/PrivateRoute";
import {ControlHomePage} from "src/components/pages/ControlHomePage";
import {SummaryPage} from "src/components/pages/SummaryPage";
import {ReportsPage} from "src/components/pages/ReportsPage"


export function App() {
    return (
        <Routes>
            <Route path="/" element={<PrivateRoute />}>
                <Route index element={<Landing/>} />
                <Route path="/:controlType/:controlId" element={<ControlHomePage/>} />
                <Route path="/:controlType/:controlId/summary" element={<SummaryPage/>} />
                <Route path="/reports/:controlId" element={<ReportsPage/>} />
            </Route>
        </Routes>
    );
}