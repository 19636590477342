/**
 * Configuration required for Amplify.
 * Ref: https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
 */

export const envConfig = {
    Auth: {
        // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'us-east-1:a48ba2d4-e2a8-4220-ace5-46a9a8e402e3',

        // (required)- Amazon Cognito Region
        region: 'us-east-1',

        // (optional) - Amazon Cognito User Pool ID
        userPoolId: 'us-east-1_4B9iFq5Ak',

        // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
        userPoolWebClientId: '2hehfmmqhes7mf76qp9d9d375s',

        // (optional) - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,

        // (optional) - Hosted UI configuration
        oauth: {
            domain: 'controllershiphub-prod' + '.auth.us-east-1.amazoncognito.com',
            redirectSignIn: 'https://' + window.location.host + '/',
            redirectSignOut: 'https://' + window.location.host+ '/',
            //  redirectSignIn: 'http://localhost:4321/',
            //  redirectSignOut: 'http://localhost:4321/',
            scope: ['openid'],
            responseType: 'code',
        },
    },
};