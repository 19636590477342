/**
 * Main entry point for the React application.
 * This file sets up the root component and necessary providers.
 */

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { ControlProvider } from './common/ControlContext';

// Import global styles
import '@cloudscape-design/global-styles/index.css';
import '@amzn/katal-components/styles.css';
import './index.scss';

// Import components and providers
import { App } from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import { AuthProvider } from './provider/AuthProvider';

// Import logger configuration
import './logger';

// Get the root element from the DOM
const container = document.getElementById('root');

// Ensure the container exists before creating the root
if (!container) {
    throw new Error('Failed to find the root element');
}

// Create a root for the React application
const root = createRoot(container);

// Render the application
root.render(
    <React.StrictMode>
        <AppInitWrapper>
            <AuthProvider>
                <BrowserRouter>
                    <ControlProvider>
                        <App />
                    </ControlProvider>
                </BrowserRouter>
            </AuthProvider>
        </AppInitWrapper>
    </React.StrictMode>
);